// import { ChainId } from '@pancakeswap-libs/sdk';
import { ChainId } from '@spookyswap/sdk';
import { Configuration } from './tomb-finance/config';
import { BankInfo, AutoCompounderInfo, LendingPoolInfo, CasinosInfo } from './tomb-finance';

const configurations: { [env: string]: Configuration } = {
  production: {
    chainId: ChainId.FTMTESTNET,
    networkName: 'Fantom Opera Testnet',
    ftmscanUrl: 'https://testnet.ftmscan.com',
    defaultProvider: 'https://rpc.testnet.fantom.network/',
    deployments: require('./tomb-finance/deployments/deployments.testing.json'),
    externalTokens: {
      WFTM: ['0xf1277d1ed8ad466beddf92ef448a132661956621', 18],
      FUSDT: ['0xb7f24e6e708eabfaa9e64b40ee21a5adbffb51d6', 6],
      BOO: ['0x14f0C98e6763a5E13be5CE014d36c2b69cD94a1e', 18],
      ZOO: ['0x2317610e609674e53D9039aaB85D8cAd8485A7c5', 0],
      SHIBA: ['0x39523112753956d19A3d6a30E758bd9FF7a8F3C0', 9],
      'USDT-FTM-LP': ['0xE7e3461C2C03c18301F66Abc9dA1F385f45047bA', 18],
      'COCONUT-TOMB-LP': ['0x5505f80099d259d1c6f6feceb7512278eb33ed2f', 18],
      'PALM-FTM-LP': ['0x20bc90bB41228cb9ab412036F80CE4Ef0cAf1BD5', 18],
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
  development: {
    chainId: 8453,
    networkName: 'Base Mainnet',
    ftmscanUrl: 'https://basescan.org',
    defaultProvider: 'https://1rpc.io/base',
    deployments: require('./tomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WFTM: ['0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', 18],
      TOMB: ['0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7', 18],
      TSHARE: ['0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37', 18],
      FUSDT: ['0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', 6], // This is actually usdc on mainnet not fusdt
      BOO: ['0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE', 18],
      ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
      SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
      SinSPIRIT: ['0x749F2B95f950c4f175E17aa80aa029CC69a30f09', 18],
      SPIRIT: ['0x5Cc61A78F164885776AA610fb0FE1257df78E59B', 18],
      LQDR: ['0x10b620b2dbAC4Faa7D7FFD71Da486f5D44cd86f9', 18],
      fUSDT: ['0x049d68029688eAbF473097a2fC38ef61633A3C7A', 6],
      BTC: ['0x321162Cd933E2Be498Cd2267a90534A804051b11', 8],
      USDC: ['0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', 6],
      ETH: ['0x74b23882a30290451A17c44f4F05243b6b58C76d', 18],
      FRAX: ['0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355', 18],
      MAI: ['0xfB98B335551a418cD0737375a2ea0ded62Ea213b', 18],
      'CRE8R': ['0x2aD402655243203fcfa7dCB62F8A08cc2BA88ae0', 18],
      'USDT-FTM-LP': ['0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D', 18],
      'COCONUT-TOMB-LP': ['0xFfCdb24f13deef5DE5Eb90EB8B1D4b9997b8C83d', 18],
      'PALM-FTM-LP': ['0xE3cE19d1f548Caaeb4F5BCB8613d0bE1A38063A4', 18],
      'TOMB-FTM-LP': ['0x2A651563C9d3Af67aE0388a5c8F89b867038089e', 18],
      'TSHARE-FTM-LP': ['0x4733bc45eF91cF7CcEcaeeDb794727075fB209F2', 18],
      'LQDR-FTM-LP': ['0x4Fe6f19031239F105F753D1DF8A0d24857D0cAA2', 18],
      'CRE8R-FTM-LP': ['0x459e7c947E04d73687e786E4A48815005dFBd49A', 18],
      'SPIRIT-FTM-LP': ['0x30748322B6E34545DBe0788C421886AEB5297789', 18],
      'fUSDT-FTM-LP': ['0xd14Dd3c56D9bc306322d4cEa0E1C49e9dDf045D4', 18],
      'USDC-FTM-LP': ['0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D', 18],
      'BTC-FTM-LP': ['0x279b2c897737a50405ED2091694F225D83F2D3bA', 18],
      'ETH-FTM-LP': ['0x613BF4E46b4817015c01c6Bb31C7ae9edAadc26e', 18],
      'FRAX-FTM-LP': ['0x7ed0cdDB9BB6c6dfEa6fB63E117c8305479B8D7D', 18],
      'MAI-FTM-LP': ['0x51Eb93ECfEFFbB2f6fE6106c4491B5a0B944E8bd', 18],
      'SPIRIT-SINSPIRIT-LP': ['0x66f0a5fd09df38416ffec46735b4cb752c43fd13', 18],

      MYSTCL: ['0xDC46c1e93B71fF9209A0F8076a9951569DC35855', 18], // done base
      SWPRS: ['0xa4AF354d466E8A68090DD9EB2CB7caF162f4c8c2', 18], // done base
      BOAT: ['0x9117580772aD7e77eb533847A8bb11303Fd1955B', 18],
      BOMB: ['0x8503eb4A136bDBeB323E37Aa6e0FA0C772228378', 18],
      BIFI: ['0xd6070ae98b8069de6B494332d1A1a81B6179D960', 18],
      DAI: ['0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E', 18],
      DEI: ['0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3', 18],
      EAGLE: ['0xeb3bA75334A58cB80C45dfBb551f03A5FdE452E6', 18],
      BASED: ['0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae', 18],
      gOHM: ['0x91fa20244Fb509e8289CA630E5db3E9166233FDc', 18],
      ATLAS: ['0x92df3eaBf7c1c2A6b3D5793f6d53778eA78c48b2', 18],
      DEUS: ['0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44', 18],
      'BOMB-FTM-LP': ['0x11d7fD8Deb7F37EA97218F70550E03fe6683df3D', 18],
      'BIFI-FTM-LP': ['0xc28cf9aeBfe1A07A27B3A4d722C841310e504Fe3', 18],
      'DEI-USDC-LP': ['0x8eFD36aA4Afa9F4E157bec759F1744A7FeBaEA0e', 18],
      'gOHM-FTM-LP': ['0xae9BBa22E87866e48ccAcFf0689AFaa41eB94995', 18],
      'ATLAS-FTM-LP': ['0xFFcF183126dF14EC4E59409bAb431885ccEEb1C2', 18],
      'COCONUT-PALM-LP': ['0x41829a2f506FD649a13FaB5518C57e3Afc776755', 18],
      'COCONUT-ETH-LP': ['0xf1Ff268580278ae71f98860dED01aEb2312e0944', 18],
      'COCONUT-USDC-LP': ['0x99E8B6c91b7D1876dF3873585510f854e9B22C20', 18],
      'PALM-USDC-LP': ['0x8708D994E0Af590B3DB14E9c0747daae5a42f75E', 18],
      'DEUS-FTM-LP': ['0x2599Eba5fD1e49F294C76D034557948034d6C96E', 18],



      '0x12FC98B8545fb5BA4824Ef948a5e5423190363a8': ['0x12FC98B8545fb5BA4824Ef948a5e5423190363a8', 18],
      '0x8DD4D2eb0693924B98BFEaE39e5C3cE5B0e12c84': ['0x8DD4D2eb0693924B98BFEaE39e5C3cE5B0e12c84', 18],
      '0x61BDB074c39a227f9f79588C1e3Cc904f38b9C19': ['0x61BDB074c39a227f9f79588C1e3Cc904f38b9C19', 18],
      '0x8792DB08e2075b49AA1c7269da1CB1117C24F815': ['0x8792DB08e2075b49AA1c7269da1CB1117C24F815', 18],
      '0x798924bcE0f5380a85706ddb70C59F59217f375e': ['0x798924bcE0f5380a85706ddb70C59F59217f375e', 18],
      '0xfFC07E2Bd4378756c96ba5F915cB731eFB052D48': ['0xfFC07E2Bd4378756c96ba5F915cB731eFB052D48', 18],
      /* NEW */
      '0xA0E52d51C83A46432a18eB78B643A0bA2b34fbe6': ['0xA0E52d51C83A46432a18eB78B643A0bA2b34fbe6', 18],
      '0x8bf7472BDcE91035E95Cca95cB51FEeaAdC52AE7': ['0x8bf7472BDcE91035E95Cca95cB51FEeaAdC52AE7', 18],
      '0x63fB0BC4a727F40Cdb41571B0D6254866fd36C67': ['0x63fB0BC4a727F40Cdb41571B0D6254866fd36C67', 18],
      '0x24F0352Bab77f73E99cd5253a6771FDeD90c00fF': ['0x24F0352Bab77f73E99cd5253a6771FDeD90c00fF', 18],
      '0x6f2407057dae200E688384eC372F169A01Ed7E70': ['0x6f2407057dae200E688384eC372F169A01Ed7E70', 18],
      '0x6896352948619132c271C3B302d8DD89F61DB8b2': ['0x6896352948619132c271C3B302d8DD89F61DB8b2', 18],
      '0xcae8b5ada97c51860fabbb323736c49ecc638cfc': ['0xcae8b5ada97c51860fabbb323736c49ecc638cfc', 18],
      '0xb8Cec5c57F1D6004ADC48DdDfa848ce8E164EFfB': ['0xb8Cec5c57F1D6004ADC48DdDfa848ce8E164EFfB', 18],
      '0x6ABd7c99E0Fc531CaEd7Fc28da6940c07545200D': ['0x6ABd7c99E0Fc531CaEd7Fc28da6940c07545200D', 18],
      '0x6185B40CB5D35a1346Fb12dA28F8Cf5e81b9dE49': ['0x6185B40CB5D35a1346Fb12dA28F8Cf5e81b9dE49', 18],
      '0x63F4138b5c5BD7E8Aa5B2e99efb01868f0FFa431': ['0x63F4138b5c5BD7E8Aa5B2e99efb01868f0FFa431', 18],
      '0xB47AE093BE781Cc5C5e3525207785A4FA8cB989c': ['0xB47AE093BE781Cc5C5e3525207785A4FA8cB989c', 18],
      '0xd598D195e77E595d70270DCCB33f6AB3c7b3B4b8': ['0xd598D195e77E595d70270DCCB33f6AB3c7b3B4b8', 18],
      '0x9D7DECe83405D5F41F970Fc5197C1732962960B9': ['0x9D7DECe83405D5F41F970Fc5197C1732962960B9', 18],
      '0xEbcf7659E41cFa1B80aA2Bebf06Aea245E0124f4': ['0xEbcf7659E41cFa1B80aA2Bebf06Aea245E0124f4', 18],
      '0xaA68e97bab8bCFea72E1371496B804386F930f79': ['0xaA68e97bab8bCFea72E1371496B804386F930f79', 18],

      '0x49E8619991f9D52c16D71E0505eEB8Bc9C6dda04': ['0x49E8619991f9D52c16D71E0505eEB8Bc9C6dda04', 18],
      '0x374EF9BC22BB718fA0a8A53230c5e2aE16c722e0': ['0x374EF9BC22BB718fA0a8A53230c5e2aE16c722e0', 18],
      '0x0301a27483E92EbDfAE00b173A48b8eC8568D4fe': ['0x0301a27483E92EbDfAE00b173A48b8eC8568D4fe', 18],
      '0x8C1BbD5AabA754c8715eB039966b0E9dFa564368': ['0x8C1BbD5AabA754c8715eB039966b0E9dFa564368', 18],
      '0x42D3e161319bd5b014c6b7f90fc34D2324456569': ['0x42D3e161319bd5b014c6b7f90fc34D2324456569', 18],
      '0xA2519B723c4c2Eee6faB3bf3074c551b61b30E73': ['0xA2519B723c4c2Eee6faB3bf3074c551b61b30E73', 18],
      '0x2Da99d58553BF60fE5bD755Ea3AC3cAA8582578E': ['0x2Da99d58553BF60fE5bD755Ea3AC3cAA8582578E', 18],
      '0x714f1b9b581826aa87e885e366d5315d6a676102': ['0x714f1b9b581826aa87e885e366d5315d6a676102', 18],
      '0x500445604Ceac98a5E889B064548BfBE0407Bf8a': ['0x500445604Ceac98a5E889B064548BfBE0407Bf8a', 18],
      '0x4d0Cb4A33663589b4003FbDc83d8cA87D9C507A5': ['0x4d0Cb4A33663589b4003FbDc83d8cA87D9C507A5', 18],
      '0x46A551E76e8E9e46D7983707925CF509A07bbDdC': ['0x46A551E76e8E9e46D7983707925CF509A07bbDdC', 18],
      '0xc5f901A80217A82D449d98012270528378E3B723': ['0xc5f901A80217A82D449d98012270528378E3B723', 18],
      '0xF9200499fF5DAA82aEFe1541F764abcB00d68FEB': ['0xF9200499fF5DAA82aEFe1541F764abcB00d68FEB', 18],
      '0x0b84df4e6974099a3eaf678f4379cb12d5c84f64': ['0x0b84df4e6974099a3eaf678f4379cb12d5c84f64', 18],
    },
    baseLaunchDate: new Date('2021-05-17 16:00:00Z'),
    bondLaunchesAt: new Date('2021-12-17T16:15:00Z'),
    masonryLaunchesAt: new Date('2021-12-17T16:15:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  MystclFarm: {
    farmName: 'MYSTCL - Farm',
    description: "Make your PFP proud by earning MYSTCL tokens",
    name: 'Generate MYSTCL with a Unit Farmer',
    poolId: 0,
    sectionInUI: 3,
    contract: 'MystclFarm',
    depositTokenName: 'MYSTCL',
    earnTokenName: 'MYSTCL',
    finished: false,
    sort: 1,
    closedForStaking: false,
    nodePrice: 1,
  },
};

export const autoCompounderDefinitions: { [contractName: string]: AutoCompounderInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding COCONUT
        - 2 = LP asset staking rewarding PALM
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  CoconutAutocompounder: {
    name: 'Coconut AutoCompounder',
    poolId: 0,
    sectionInUI: 4,
    contract: 'CoconutAutocompounder',
    depositTokenName: 'COCONUT-TOMB-LP',
    earnTokenName: 'PALM',
    vaultContract: 'CoconutVaultAutocompounder',
    autocompounderContract: 'CoconutAutocompounder',
    masterChefContract: 'CoconutTombLPPalmRewardPool',
    pairTokenName1: 'COCONUT',
    pairTokenName2: 'WFTM',
    linkVault: '0x12FC98B8545fb5BA4824Ef948a5e5423190363a8',
    linkCompounder: '0x2CD89B6144D1928441F6a00D8738Ad46D4C0E394',
    finished: false,
    sort: 8,
    closedForStaking: false,
  },
  TombAutocompounder: {
    name: 'Tomb AutoCompounder',
    poolId: 1,
    sectionInUI: 4,
    contract: 'TombAutocompounder',
    depositTokenName: 'TOMB-FTM-LP',
    earnTokenName: 'TSHARE',
    vaultContract: 'TombVaultAutocompounder',
    autocompounderContract: 'TombAutocompounder',
    masterChefContract: 'TombFtmLPTShareRewardPool',
    pairTokenName1: 'TOMB',
    pairTokenName2: 'WFTM',
    linkVault: '0x8DD4D2eb0693924B98BFEaE39e5C3cE5B0e12c84',
    linkCompounder: '0xFeaaBde6488176C69CD694Ddc35A5A31718187C0',
    finished: false,
    sort: 9,
    closedForStaking: false,
  },
};

export const lendingPoolDefinitions: { [contractName: string]: LendingPoolInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding COCONUT
        - 2 = LP asset staking rewarding PALM
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  CoconutAutocompounder: {
    name: 'Coconut AutoCompounder',
    poolId: 0,
    sectionInUI: 5,
    contract: 'CoconutAutocompounder',
    depositTokenName: 'COCONUT-TOMB-LP',
    earnTokenName: 'PALM',
    vaultContract: 'CoconutVaultAutocompounder',
    autocompounderContract: 'CoconutAutocompounder',
    masterChefContract: 'CoconutTombLPPalmRewardPool',
    pairTokenName1: 'COCONUT',
    pairTokenName2: 'WFTM',
    linkVault: '0x12FC98B8545fb5BA4824Ef948a5e5423190363a8',
    linkCompounder: '0x2CD89B6144D1928441F6a00D8738Ad46D4C0E394',
    finished: false,
    poolType: 'Vault+Farm',
    sort: 10,
    closedForStaking: false,
  },
};

export const casinosDefinitions: { [contractName: string]: CasinosInfo } = {
  FantomBlackjack: {
    name: 'Earn COCONUT by TOMB',
    poolId: 1,
    sectionInUI: 99,
    contract: 'FantomBlackjack',
    depositTokenName: 'COCONUT',
    finished: false,
    sort: 1,
    closedForStaking: false,
  },
  FantomRoulette: {
    name: 'Earn COCONUT by BASED',
    poolId: 2,
    sectionInUI: 99,
    contract: 'FantomRoulette',
    depositTokenName: 'TOMB',
    finished: false,
    sort: 2,
    closedForStaking: false,
  },
};

export default configurations['development'];
// export default configurations[process.env.NODE_ENV || 'development'];
